var kc = window.kc;

kc.utils = { dom : {} };

kc.utils.dom.createTag = function(wrapper)
{
	var split, tag, attrs, r, div = document.createElement('div');
	
	split = wrapper.split(/\s/);
	tag = split.shift();
	attrs = split.join('');
	
	div.innerHTML = "<" + tag + " " + attrs + "></" + tag + ">";

	div.removeChild(r = div.firstChild)

	return r;
}

// replace `node` with `wrapper`. `wrapper` is a string representing a tag with optional attributes e.g. 'span class="paragraph"'
kc.utils.dom.replaceTag = function(node, wrapper) 
{
	var pn, pnn, newNode;

	newNode = kc.utils.dom.createTag(wrapper);
	
	pn = Polymer.dom(node);
	pnn = Polymer.dom(newNode);

	while(pn.firstChild)
		pnn.appendChild(pn.firstChild);

	utils.replaceNodeWith(node, newNode);

	return newNode;
}
 
kc.utils.dom.replaceWithOwnChildren = function(node) {
	var pn = Polymer.dom(node), 
		pp = Polymer.dom(pn.parentNode);
	
	while(pn.firstChild)
		pp.insertBefore(pn.firstChild, node);
	
	pp.removeChild(node);
	
	return node;
}
