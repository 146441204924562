console.log('...and i am medialib!');

//var components = require('koacms-components');

/*
function initDropZone(root)
{
	if(!root) root = document;
	
	components.DropZone(document.getElementsByClassName("dropzoneForm")[0],
		function(file) { 
			var request = new XMLHttpRequest();
			request.open('GET', '../partial/ls/', true);
	
			request.onload = function() {
			  if (request.status >= 200 && request.status < 400) {
				// Success!
				// var data = JSON.parse(request.responseText);
				console.log(request.responseText);
				var listing = document.getElementById("listing");
				listing.outerHTML = request.responseText;
			  } else {
				// We reached our target server, but it returned an error
				
			  }
			};
	
			request.onerror = function() {
			  // There was a connection error of some sort
			};
	
			request.send();			
	})
}

initDropZone();

module.exports = {
	createdCallback : initDropZone
}*/

//.dropzoneTarget


//console.log(dropzone);
//require('./req.js');


