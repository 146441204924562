require('./wrapPostMedia'); 
    
window.onscroll = function() {
	if(document.getElementById('sticked'))
		scrollToFix()
};

function scrollToFix () {
	var scrollTop = window.pageYOffset;
	if( scrollTop > 400 && window.innerWidth > 1200 ){
		document.getElementById('sticked').style.top="20px";
		document.getElementById('sticked').style.position="fixed";
	}
	else
	{
		document.getElementById('sticked').style.position="relative";
		document.getElementById('sticked').style.top="0px";
	}
}