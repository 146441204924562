var domReady = require('domready');



(function() {
	window.__preventDefault = window.__preventDefault ||
		function(ev) {
			if(!window.__preventDefault)
				return;
			if(ev.target.tagName = "A") {
				ev.preventDefault();
				ev.stopPropagation();
			}
		}
	document.addEventListener('click', window.__preventDefault, true)
	HTMLImports.whenReady(function() {
		document.removeEventListener('click', window.__preventDefault)
		delete window.__preventDefault;
	});
})();

// avoid processing of invisible banners
window.addEventListener('load', function() {
	var matches = document.querySelectorAll("ins.ADSENSE");

		   Array.from(matches).forEach(function(element) {
			   var parentElement = element.parentElement;
			   if (window.getComputedStyle(parentElement).getPropertyValue("display") === "none")  { 
				   element.remove(); 
			   } else {
				element.classList.remove("ADSENSE");
				element.classList.add("adsbygoogle");
				   (adsbygoogle = window.adsbygoogle || []).push({}); 
			   }
		   });
   
   });

HTMLImports.whenReady(function() {
	require("./utils");
	require("./domready");
	require("./responsive-switch"); 
	// require("./kacey-editor/kacey-editor")
	 
	require("./fbEverywhere")

	//require("./fixPaperDropdownMenu");

	kc.theme = 'vinegret';

	$ = function (el) {
		if(el instanceof HTMLElement)
			return el;

		return document.querySelector(el);
	}

	HTMLImports.whenReady(function() {
		domReady(function() { window.addEventListener('WebComponentsReady',setupNewsScrollers) })
		domReady(function() { window.addEventListener('WebComponentsReady', loadLazyImages) })
		domReady(function() { window.dispatchEvent(new Event('kacey-ui-ready')) })
	})

	var _loadLazyByTypeThrottled = _.throttle(_loadLazyByType, 500);
	function loadLazyImages() {
		window.addEventListener('scroll', _loadLazyByTypeThrottled); //setTimeout(_loadLazyImages, 1000);
		_loadLazyByType();
	}
	
	function _loadLazyByType() {
		var bgs = _loadLazyImages("lazy-bg", function(el, url) { el.style.backgroundImage = "url(" + url + ")"; });
		var srcs = _loadLazyImages("lazy-src", function(el, url) { el.src = url; });
		if(!bgs && !srcs) 
			window.removeEventListener('scroll', _loadLazyByTypeThrottled);
	};
				
	function _loadLazyImages(attr, onLoad) {
		var bgs = [].slice.call(document.querySelectorAll("[" + attr + "]"));
		
		var currScroll = window.scrollY + window.innerHeight + 200; // 200 is "sensitivity"
		var relevant = bgs
				.filter(function(el) { 
					return el.offsetHeight && (el.offsetTop < currScroll);
				})
		
		relevant.forEach(function(el) {
				var url = el.getAttribute(attr);
				if(!url) return;
				var im = document.createElement('img');
				im.onload = function() {
					el.removeAttribute(attr)
					onLoad(el, url)
				}
				im.src = url;
			})
			
		return bgs.length;
	}

	function setupNewsScrollers() {
		setTimeout(function() {
			latestNews();
			featuredNews('#featured-news-scroller', '#featured-news-thumbnails');
		},100); // was 1000 - 16.1.25

	};

	window.addEventListener("resize", featuredNews.bind(null, '#featured-news-scroller', '#featured-news-thumbnails'));

	function featuredNews(scrollerSelector, thumbnails)
	{
		var scrollerItemsContainer,
			el = $(scrollerSelector),
			iscroll, thumbnails, autoScroll, currentPage, slideWidth;

		if(!el) 
			return;

		scrollerItemsContainer = document.getElementById('scroller-items');

		if(!scrollerItemsContainer.childNodes.length)
			return

		slideWidth = scrollerItemsContainer.childNodes[0].querySelector('.featured-sizer').getBoundingClientRect().width + 20;
		scrollerItemsContainer.style.width = ((scrollerItemsContainer.childNodes.length) * slideWidth) + "px";
		[].slice.call(scrollerItemsContainer.childNodes).forEach(function(slide) { slide.style.width = slideWidth + "px" });
		
		if(!el.iscroll)
		{
			setTimeout(function() {
				featuredNews(scrollerSelector, thumbnails);
			},
			1000);
			return;
		}
		
		
		iscroll = el.iscroll;
		thumbnails = $(thumbnails);
		autoScroll = true;
		currentPage = 0;

		if(!el || !thumbnails || !thumbnails.childNodes)
			return;

		var goToPage = function(i) {
			currentPage = i;

			if(typeof currentPage == 'undefined')
				currentPage = iscroll.pages.pageX;

			iscroll.goToPage(i, 1);
		}

		var selectThumbnail = function(i) {
			var next = (typeof i != "undefined") ? i : (iscroll.currentPage.pageX || 0);
			
			[].forEach.call(thumbnails.childNodes, function(el) {
				if(el.classList.contains('selected'))
					el.classList.remove('selected');
			});

			if(!thumbnails.childNodes[next])
				return;
			
			thumbnails.childNodes[next].classList.add('selected');
		}

		selectThumbnail(0);

		//	return;
		clearInterval(featuredNews._currentInterval);
		
		featuredNews._currentInterval = 
		setInterval(function() {
			if(el.isReady && autoScroll)
			{
				if(++currentPage >= iscroll.pages.length)
					currentPage = 0;

				goToPage(currentPage);
				selectThumbnail(currentPage);
			}
		}, 4000);

		el.addEventListener('mousedown', function(e) {
			selectThumbnail();
			autoScroll = false;
		});
		
		iscroll.on("scrollEnd", function( event ) {
			selectThumbnail();
		}, false);

		iscroll.on("flick", function() {
			autoScroll = false;
		});

		thumbnails.addEventListener('click', function(e) {
			var page = e.target.getAttribute('data-iscroll-page');
			goToPage(page);
			selectThumbnail(page);
			autoScroll = false;
		});
		
		iscroll.refresh()
	}

	function latestNews()
	{
		var	el = $('#latest-news-scroller'),
			iscroll;

		if(!el)
			return;
		
		iscroll = el.iscroll;

		if(!iscroll) return setTimeout(latestNews, 500);

		$("#latest-news-prev").addEventListener("click", function(e) {
			if(!iscroll) iscroll = el.iscroll;
			if(iscroll.currentPage.pageY > 0)
				el.iscroll.prev();

			e.preventDefault();
		});
		$("#latest-news-next").addEventListener("click", function(e) {
			if(!iscroll) iscroll = el.iscroll;
			if(iscroll.currentPage.pageY < iscroll.pages[0].length - 1)
				el.iscroll.next();

			e.preventDefault();
		});
	}


	window.googleCustomSearchFactory = (function (){
		"use strict"
		// Api key
		var MAX_RESULTS = 100,
			key = 'AIzaSyBmlMnLp6VW3XNlUIxZrU0HT6zZatwvXSo', //'AIzaSyAWaNgRmBHqMeJqlx3TSCsU8VVqWAozTt0',
			// Search engine id
			cse = '006751552398986616762:mgw1spwayji', //'016198005642424819754:jfdt3w7nys8',
			currentQuery,
			start = 1,
			count = 10;

		function setQuery(query, start) {
			window.history.pushState(null, null, "?q=" + query + "&start=" + (start || 1));
		}
			
		function next(){
			start = Number(start) + Number(count);
			search(start);
			scrollToTop();
		}

		function prev(){
			if(start < count)
				return;
			
			start = Number(start) - Number(count);
			search(start);
			scrollToTop();
		}

		function getQueryVariable(variable) {
			var query = window.location.search.substring(1),
				vars = query.split("&"),
				i, pair;
				
			for (i = 0; i < vars.length; i++) {
				pair = vars[i].split("=");
				
				if(pair[0] == variable)
					return decodeURIComponent(pair[1]);
			}
			return(false);
		}


		function searchFromBox(event){
			var keycode = event.keyCode || event.which;

			if(keycode && keycode != 13)
				return;
			
			var newQuery = decodeURIComponent(document.getElementById("searchBox").value);

			if(newQuery.length < 3)
				return
			
			currentQuery = newQuery;	
			start = 1;
			
			search();
		};

		function search() {
			var xhr = new XMLHttpRequest();
			var q = 'https://www.googleapis.com/customsearch/v1?key=' + key + '&cx=' + cse + '&q=' + currentQuery.replace(/\+/," ").replace(/ /g, ",") + '&start=' + start + "&num=" + count;
			xhr.open('GET', encodeURI(q));
			
			console.log(q);
			console.log(encodeURI(q));
			
			xhr.onload = function () {
				if (xhr.status === 200) {
					renderSearchResults(xhr.responseText)
				}
				else {
					console.error('Request failed. Returned', xhr.status, xhr.responseText);
				}
			};
			xhr.send();
			
			setQuery(currentQuery, start)
		}
		
		function renderSearchResults(rawData) {
			var results = [], data, items, $_, display, hasMore;

			$_ = document.getElementById.bind(document),
			display = function(value) { 
							var ids = [].slice.call(arguments);
							ids.shift(); 
							ids
								.forEach(function(el) { 
									var e = $_(el); 
									if(e) e.style.display = value 
								}) };
				
			if(rawData)
			{
				data = JSON.parse(rawData);
				items = data.items;
				hasMore = Number(start) + Number(count) < MAX_RESULTS && (data.searchInformation.totalResults > Number(start) + Number(count));
			}

			$_('hide-on-successful-search').style.display = items ? 'none' : 'block';

			if(items){
				for (var i = 0; i < items.length; i++) {
					if(items[i].snippet)
						results.push(
								"<div class='post-template'><h5>" + // <img src='" + (items[i].pagemap.cse_thumbnail[0] || {}).src + "'>
								"<a href=" + items[i].link +">" +  
								items[i].title + "</a></h5><div class='desc'>" +  items[i].snippet + "</div>" +   
								
								// time is not available for some results (google, why?)
								(items[i].pagemap.metatags[0]['article:published_time'] ? 
									"<div class='meta'><span class='ico16 ico16-calendar'>" + items[i].pagemap.metatags[0]['article:published_time'] + "</span>"
									: '') +
								"<span class='ico16 ico16-link'><a href="+ items[i].link + ">"+ items[i].link +"</a></span></div></div>");
				}

				setTimeout(function(){ document.getElementById("search-results").innerHTML = results.join('') });
				
				display("block", "search-results", "search-pagination")
				display("none", "nothingfound")
			}
			else
			{
				display("none", "search-results", "search-pagination")
				display("block", "nothingfound")
			}
			
			display(start > 1 ? "inline-block" : "none", "search-pagination-prev")
			display(hasMore ? "inline-block" : "none", "search-pagination-next")
			
			display(items ? "block" : "none", "search-summary-block");
			if(items)
			{
				$_("search-summary-current").innerHTML = Math.floor(start / count) + 1;
				$_("search-summary-total").innerHTML = Math.min(Math.floor(data.searchInformation.totalResults / count) + 1, Math.floor(MAX_RESULTS / count));
			}
		}
		
		currentQuery = getQueryVariable('q');
		start = getQueryVariable('start') || 1;

		search();
		
		return {
			next : next,
			prev : prev,
			searchFromBox : searchFromBox 
		}
	});


	global.uiListStyleSwitcher = function(opts) {
		var storedVal, elements, $_;

		$_ = document.querySelector.bind(document);
		
		els = {
			container : $_(opts.container),
			list : $_(opts.listButton),
			module : $_(opts.moduleButton)
		}

		storedVal = (localStorage && localStorage.getItem(opts.storageKey)) || opts.defaultValue;
		els[storedVal].raised = true;
		els.container.classList.add(storedVal);
		
		els.list.addEventListener('click', switcher);
		els.module.addEventListener('click', switcher);
		
		function switcher(ev) {
			var n = ev.currentTarget.id, 
				o = n == 'list' ? 'module' : 'list';
				
			els[n].raised = true;
			els[o].raised = false;
			
			els.container.classList.add(n);
			els.container.classList.remove(o);
			
			window.localStorage.setItem(opts.storageKey, n);
		}
	};


	function scrollToTop(scrollDuration) {
		var offset = document.getElementById('search-header').offsetTop - 12;
		
		scrollDuration = scrollDuration || 150;
		var scrollStep = -window.scrollY / (scrollDuration / 15),
			scrollInterval = setInterval(function(){
			if (window.scrollY >= offset) {
				window.scrollBy(0, scrollStep );
			}
			else 
				clearInterval(scrollInterval);
		}, 15);
	}

	// layout-master helpers
	window.menuB = function() {
		document.getElementById("drop2").checked = false;
		document.getElementById("mobile-nav").classList.remove("openS");
		document.getElementById("mobile-nav").classList.toggle("openM");

	}
	 
	window.searchB = function searchB() {
		document.getElementById("drop").checked = false;
		document.getElementById("mobile-nav").classList.remove("openM");
		document.getElementById("mobile-nav").classList.toggle("openS");
		setTimeout(function(){ document.getElementById("search2").focus(); }, 1000);
	}

	window.addEventListener("scroll", runOnScroll);

	function runOnScroll(){
		var x = document.querySelector('.scroll');
		if(!x)
			return;
		
		var poz =	window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

		x.style.opacity = poz > 300 ? "1" : "0";
	}
	// Function to animate the scroll
	var smoothScroll = function (anchor, duration) {

			// Calculate how far and how fast to scroll
		var startLocation = window.pageYOffset;
		var endLocation = anchor.offsetTop;
		var distance = endLocation - startLocation;
		var increments = distance/(duration/16);
		var stopAnimation;

		// Scroll the page by an increment, and check if it's time to stop
		var animateScroll = function () {
			window.scrollBy(0, increments);
			stopAnimation();
		};

		// If scrolling down
		if ( increments >= 0 ) {
			// Stop animation when you reach the anchor OR the bottom of the page
			stopAnimation = function () {
				var travelled = window.pageYOffset;
				if ( (travelled >= (endLocation - increments)) || ((window.innerHeight + travelled) >= document.body.offsetHeight) ) {
					clearInterval(runAnimation);
				}
			};
		}
		// If scrolling up
		else {
			// Stop animation when you reach the anchor OR the top of the page
			stopAnimation = function () {
				var travelled = window.pageYOffset;
				if ( travelled <= (endLocation || 0) ) {
					clearInterval(runAnimation);
				}
			};
		}

		// Loop the animation function
		var runAnimation = setInterval(animateScroll, 16);

	};

	// Define smooth scroll links
	var scrollToggle = document.querySelectorAll('.scroll');


	// For each smooth scroll link
	[].forEach.call(scrollToggle, function (toggle) {

		// When the smooth scroll link is clicked
		toggle.addEventListener('click', function(e) {

			// Prevent the default link behavior
			e.preventDefault();

			// Get anchor link and calculate distance from the top
			var dataID = toggle.getAttribute('href');
			var dataTarget = document.querySelector(dataID);
			var dataSpeed = toggle.getAttribute('data-speed');

			// If the anchor exists
			if (dataTarget) {
				// Scroll to the anchor
				smoothScroll(dataTarget, dataSpeed || 500);
			}  

		}, false);

	});

	if(/future=/.test(document.location.search))
		document.body.addEventListener('click', function(ev) {
			(ev.path || [ev.target])
				.filter(function(a) { return a.tagName == "A" && a.href && !a.__inFuture })
				.forEach(function(a) { a.__inFuture = a.href = /vinegret|localhost/.test(a.href) ? a.href += document.location.search : a.href; })
		})

		
});

