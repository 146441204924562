
(function () {
	var domReady = require('domready');
	//HTMLImports.whenReady(function() {	
	window.addEventListener('WebComponentsReady', wrapPostMedia);
	//})

	function getElementsToWrap() {
		var kc = window.kc;
		if (!/koacms-post|koacms-page/.test(kc.ns))
			return;

		var b = 0;
		var root = document.querySelector('article.article-content');

		if (!root || root.wasProcessed)
			return

		root.wasProcessed = true;

		return root.querySelectorAll('[embedded-media]');
	}

	function getImages() {
		var root = document.querySelector('article.article-content');

		return [].slice.call(root.querySelectorAll('img') || []);
	}

	function hideImage(img) {
		// img.setAttribute("src", null);
		img.style.display = 'none';
		img.style.height = '0px';
		// img.setAttribute("height", "0");
		img.height = 0;
	}

	var tryFetchImageCache = {};
	function tryFetchImage(img) {
		var src = img.src;
		var p = tryFetchImageCache[src] || (tryFetchImageCache[src] = fetch(src));
		p
			.then(function (r) {
				if (r.status == 404)
					hideImage(img);
			})
			.catch(function (e) {
				hideImage(img);
			})
	}

	function wrapPostMedia() {
		getImages().forEach(function (i) {
			tryFetchImage(i);
			// i.setAttribute("alt", "")
			// i.addEventListener("error", function () {
			// 	setTimeout();
			// })
		})


		var elements = getElementsToWrap();

		// wrapping of images was replaced with ir-gallery which picks the images from one central component
		//var i = root.querySelectorAll('img');
		//wrapMany(i, 'paper-dialog-scrollable',{ preprocess : preprocessImage } );

		wrapMany(elements, 'embedded-media');

		linksToBlank()
	};

	/*	function hasAncestor(nodeName, node) {
			while(node)
				if(node.tagName.toLowerCase() == nodeName)
					return node;
				else
					node = Polymer.dom(
		}*/

	var wrapOne, wrapMany;

	function linksToBlank() {
		var postNode = document.querySelector('.post article.content');
		if (!postNode)
			return;
		Array.prototype.slice.call(
			postNode.querySelectorAll('a') || []
		)
			.forEach(function (el) { if (!el.target) el.target = '_blank' });
	}

	// remove wrapping <a> if points to same image
	function preprocessImage(img) {
		var a = img.parentNode,
			p = a.parentNode,
			imgsrc = img.src
		r = img;

		//if(a.tagName == 'FIGURE')
		//{
		//	img = a;
		//	a = img.parentNode;
		//	if(a)
		//		p = a.parentNode;
		//}
		if (a.tagName == 'A' && a.href == imgsrc) {
			p.insertBefore(img, a);
			p.removeChild(a);
		}

		return img;
	}

	function removeFigure(node) {
		var p = Polymer.dom(node).parentNode, pp;
		if (p.tagName == 'FIGURE') {
			pp = Polymer.dom(p).parentNode;
			pp.insertBefore(Polymer.dom(node).cloneNode(true), p);
			pp.removeChild(p);
		}
	}

	function wrapOne(node, wrapper, opts, i) {
		var split, html, tag, attrs, r, div, htmlToWrap, img;

		opts = opts || {};

		htmlToWrap = opts.htmlToWrap || function (n) { return n.outerHTML };

		if (opts.preprocess)
			node = opts.preprocess(node);

		div = document.createElement('div');
		var div2 = document.createElement('div');

		split = wrapper.split(/\s/);
		tag = split.shift();
		attrs = split.join('');

		var nodeToWrap = (node.parentNode && node.parentNode.tagName == 'FIGURE') ? node.parentNode : node;
		html = "<paper-dialog class='dialog' with-backdrop id='dialog" + i + "'><paper-icon-button noink dialog-dismiss icon='close'></paper-icon-button><" + tag + " " + attrs + " >" + htmlToWrap(nodeToWrap) + "</" + tag + "></paper-dialog>";

		if (node.tagName == 'IMG') {
			//set clickable
			if (node.naturalWidth && node.naturalWidth > 667) {
				node.setAttribute("onclick", "dialog" + i + ".open()");
				node.setAttribute("class", "dialog-view");
			}
			//if perent FIGURE
			if (node.parentNode.tagName == 'FIGURE') {
				var fig = node.parentNode.getElementsByTagName('FIGCAPTION');
				node = node.parentNode;
			}
		}

		if (node.tagName == 'IFRAME') {
			html = "<" + tag + " " + attrs + ">" + htmlToWrap(node) + "</" + tag + ">";
		}

		Polymer.dom(div).innerHTML = html;
		if (img)
			Polymer.dom(div2).innerHTML = img;

		if (!opts.onlyCreate) {
			Polymer.dom(Polymer.dom(node).parentNode).insertBefore(r = Polymer.dom(div).firstChild, node);
			if (img)
				Polymer.dom(Polymer.dom(node).parentNode).insertBefore(r = Polymer.dom(div2).firstChild, node);

			if (node.tagName != 'FIGURE' && node.tagName != 'IMG') {
				node.parentNode.removeChild(node);
			}
		}
		else
			return div.firstChild;

		if (opts.postprocess)
			opts.postprocess(r);

		return r;
	}

	function createClone(node) {
		var div = document.createElement('div');
	}

	function wrapMany(nodeList, wrapper, opts) {
		if (!nodeList) return;

		[].forEach.call(nodeList, function (n, i) {

			if (n.tagName == 'IMG') {
				n.onload = wrapOne(n, wrapper, opts, i);

			} else {
				wrapOne(n, wrapper, opts, i);
			}
		});

	}
}
)();
