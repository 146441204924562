//require("../../../bower_components/webcomponentsjs/webcomponents-lite.min.js"); 

require("../../lib/cycle.js");
window.qs = require("./query.js");


window.addEventListener('WebComponentsReady', function(e) {
	var noPolymer = document.getElementById('noPolymerAvailable');
	if(noPolymer) noPolymer.style.display = 'none';
});

